.editor-line {
    position: relative;
}

.editor-line::after {
    content: attr(data-syllable-count);
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0.5;
}

.editor-line-text {
    margin-right: 1em;
}

.poem-title {
    border-style: none none inset none;
    width: 100%;
}